import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <>
            <Header />
            <div className="privacy-policy-container">
                <h1>PRIVACY POLICY</h1>
                
                <section>
                    <p>LUCKY PENNY FILMS (OPC) is an IT company venturing as an online gaming platform. The company offers a wide range of online games to customers mainly Ludo Games - LUCKY PENNY FILMS is created and owned by RAGHAVENDRA DIWAN.</p>
                    <p>WHEREAS this Privacy Policy is an electronic documentary record in the form of an electronic contract formed under the provisions of Information Technology Act, 2000 and the rules made thereunder as amended till date. This Privacy Policy does not require any physical, electronic or digital signature and is a legally binding document between "YOU" (USER) and "LUCKY PENNY FILMS".</p>
                    <p>WHEREAS LUCKY PENNY FILMS is genuinely committed to protecting and respecting the privacy of all customers, users and APP users. We take the privacy of our users very seriously. We are committed to safeguarding the privacy of our users while providing a personalized and valuable service.</p>
                    <p>WHEREAS this Privacy Policy (this "Policy") sets out how LUCKY PENNY FILMS collects and processes personal information about you when you visit our APP named "LUDO CARNIVAL" when you play our game (our "Game"), or when you otherwise do business or make contact with us.</p>
                </section>

                <section>
                    <h2>What information do we collect?</h2>
                    <p>LUCKY PENNY FILMS collects data to enable us to operate the Game effectively, and to provide you with the best experiences on our website and our Game.</p>
                    <ol type="A">
                        <li><strong>Information you give us directly:</strong> You provide some of this data to us directly, such as when you register to use our Game, subscribe to a newsletter, respond to a survey, contact us for support, or contact us as a prospective user, vendor, supplier, or consultant.</li>
                        <li><strong>Information you give us indirectly:</strong> We may collect information about you from our other sources for internal reasons, primarily for business and operational purposes. We only use this information in line with the privacy notice and privacy commitments of the relevant group company we have collected your information from.</li>
                        <li><strong>Information we collect automatically:</strong> We get some of your data by recording how you interact with our APP and our Game. We also obtain and process data in the context of making the Game available to you. When you interact with us, our servers keep an activity log unique to you that collects certain administrative and traffic information including time of access and date of access.</li>
                    </ol>
                </section>

                <section>
                    <h2>What do we use your information for?</h2>
                    <ol type="i">
                        <li><strong>Providing the Game:</strong> To carry out your transactions with us and to make the Game available to you. In certain cases, the Game may include personalized features and recommendations to enhance your enjoyment, and automatically tailor your experience based on the data we have about you.</li>
                        <li><strong>Technical support:</strong> To diagnose problems, and to provide customer care and other support services.</li>
                        <li><strong>Improving the Game:</strong> To continuously improve our "APP" and our Game, including system administration, system security, and adding new features or capabilities.</li>
                        <li><strong>Business Operations:</strong> To develop aggregate analysis and business intelligence that enable us to operate, protect, make informed decisions, and report on the performance of our business.</li>
                        <li><strong>Promotions:</strong> To administer, contests, promotions, surveys, or other "APP" features.</li>
                        <li><strong>Improving Advertising Campaigns:</strong> To improve our advertising campaigns, primarily in an attempt to prevent targeting you with advertisements that are not relevant to you.</li>
                        <li><strong>Sending Periodic Emails:</strong> To send you periodic marketing emails about our Game which you can unsubscribe from at any time using the link provided in the email message.</li>
                        <li><strong>General:</strong> To respond to your enquiries and requests relating to our Game, to create and administer your account, and to provide us with information and access to resources that you have requested from us. To improve customer service, to help us improve the content and functionality of our Game, to better understand our users, to protect against wrongdoing, to enforce our Terms of Use, and to generally manage our business.</li>
                        <li><strong>Communications:</strong> To communicate with you, and to personalize our communications with you.</li>
                    </ol>
                </section>

                <section>
                    <h2>How do we protect your information?</h2>
                    <p>We understand the importance of information security and the techniques needed to secure information. We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our gateway providers database only to be accessible by those authorized with special access rights to such systems and are required to keep the information confidential.</p>
                </section>

                <section>
                    <h2>Protection of Children's Privacy</h2>
                    <p>Our "APP" does not address anyone under the age of 13 years. The services we provide are not intended for or directed at people under the age of thirteen (13) (or the lawful age in their respective jurisdiction). We do not knowingly or intentionally collect personal information from anyone under the age of 13. Any person who provides their information to us through any part or at any stage of our website specifically represents and warrants to us that they are thirteen (13) years of age (or the lawful age in their respective jurisdiction) or older. If we become aware that a minor has attempted to or has submitted information to us, we will not accept such information and will take immediate steps to delete such information from our records.</p>
                </section>

                <section>
                    <h2>How do we ensure that our processing systems remain confidential, resilient and available?</h2>
                    <p>Since we believe that personal and non-personal data of our users is extremely valuable and vulnerable, a variety of measures have been taken by us to ensure that our data processing systems remain confidential, resilient and available. We commit to maintaining strong physical and logical access controls and conduct regular penetration testing to identify and address potential vulnerabilities. In addition to sufficient number of firewalls, we use Secure Socket Layer (SSL) technology to encrypt and protect personal and non-personal data of users against its unauthorized use, access, disclosure or its loss, misuse and alteration.</p>
                    <p>It is hereby cautioned that no transmission of data over the internet is guaranteed to be completely secure. It may be possible for third parties not under the control of LUCKY PENNY FILMS to intercept or access transmissions or private communications unlawfully. While we strive to protect personal and non-personal information, neither LUCKY PENNY FILMS nor our service providers can ensure or warrant the security of any information you transmit to us over the internet. Any such transmission is at your own risk.</p>
                </section>

                <section>
                    <h2>Do we disclose any information to outside parties?</h2>
                    <p>We share your information with our other group companies or affiliates for internal reasons, primarily for business and operational purposes based on strict adherence and in compliance with this Policy. We use our best efforts to use information in aggregate form (so that no individual User is identified) for the following purposes:</p>
                    <ol type="A">
                        <li><strong>Business Transactions:</strong> If LUCKY PENNY FILMS is involved in a merger, acquisition, asset sale, etc., your data may be transferred. We will provide you with notice before your data is transferred and becomes subject to a different Privacy Policy.</li>
                        <li><strong>Disclosure for Law Enforcement:</strong> Under certain circumstances, LUCKY PENNY FILMS may be required to disclose your data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</li>
                        <li><strong>Legal Requirements:</strong> LUCKY PENNY FILMS may disclose your data in the good faith belief that such action is necessary:
                            <ul>
                                <li>To comply with a legal obligation.</li>
                                <li>To protect and defend the rights or property of LUCKY PENNY FILMS.</li>
                                <li>To prevent or investigate possible wrongdoing in connection with the gaming services.</li>
                                <li>To protect against legal liability</li>
                            </ul>
                        </li>
                    </ol>
                    <p>We may use and share aggregated non-personal information with third parties for marketing, advertising, and analytics purposes. We do not sell or trade your personal information.</p>
                </section>

                <section>
                    <h2>How to access and control your personal data?</h2>
                    <p>You can view, access, edit, delete, or request a copy of your personal data for many aspects of the Game via your user dashboard. In view of this, the user is allowed to:</p>
                    <ol type="a">
                        <li>access your personal data on your account's user dashboard; or</li>
                        <li>request a copy of your personal data; or</li>
                        <li>have your personal data deleted on your account's user dashboard; or</li>
                    </ol>
                    <p>Any of the above requests may be emailed at <a href="mailto:info@ludocarnival.com" className="email-link">info@ludocarnival.com</a>. We shall endeavor to fulfil your requests within 30 days.</p>
                </section>

                <section>
                    <h2>Third Party Links</h2>
                    <p>We may include or offer third party products or services on our website or through our Game. If you access other APPs through links, the operators of these APPs may collect information from you that will be used by them in accordance with their respective privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites/APPs. Nonetheless, we seek to protect the integrity of our APP and welcome any feedback about these sites/APPs.</p>
                </section>

                <section>
                    <h2>How long does LUCKY PENNY FILMS keep my information?</h2>
                    <p>Information that we collect will be retained only for as long as is necessary to fulfil the purposes outlined above in this Policy (this will generally be for the duration of time where you utilize our services) or to comply with our legal obligations. We may retain your information further for a period of time specifically required by applicable regulations or laws, such as retaining the information for tax and accounting and financial services regulations record keeping obligations. When determining the relevant retention periods for your information, we will take into account factors including:</p>
                    <ol type="a">
                        <li>our contractual obligations and rights in relation to the information involved;</li>
                        <li>legal obligation(s) under applicable laws to retain data for a certain period of time;</li>
                        <li>our legitimate interests where we have carried out balancing tests;</li>
                        <li>potential disputes; and</li>
                        <li>Guidelines issued by relevant data protection authorities.</li>
                    </ol>
                    <p>Otherwise, we securely erase your information once this is no longer needed.</p>
                </section>

                <section>
                    <h2>Where do we store and process Personal Data?</h2>
                    <p>Personal data collected by LUCKY PENNY FILMS may be stored and processed in India or in any other country where LUCKY PENNY FILMS or its affiliates, subsidiaries or service providers maintain facilities. The storage location(s) are chosen in order to operate efficiently, to improve performance, and to create redundancies in order to protect the data in the event of an outage or other problem. We take steps to ensure that the data we collect is processed according to the provisions of this Policy, and the requirements of applicable law wherever the data is located.</p>
                </section>

                <section>
                    <h2>Updates and Changes to Privacy Policy</h2>
                    <p>We reserve the right, at any time, to add to, change, update, or modify this Privacy Policy. If we do, then we will post these changes on the APP. In all cases, use of information we collect is subject to the Privacy Policy in effect at the time such information is collected. We encourage you to periodically review this privacy statement to learn how LUCKY PENNY FILMS is protecting your information.</p>
                </section>

                <section>
                    <h2>How to contact us?</h2>
                    <p>If you have any privacy concern, complaint or question, please feel free to contact us by sending us an email at <a href="mailto:info@ludocarnival.com" className="email-link">info@ludocarnival.com</a>. We will respond to your queries within 30 days.</p>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;