import React from 'react';
import Slider from 'react-slick';
import image1 from '../assets/images/image1.png'; // Update this path
import image2 from '../assets/images/image2.png'; // Update this path
import image3 from '../assets/images/image3.png'; // Update this path
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HowToPlaySlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="how-to-play-container" id='How-to-Play'>
            <h2 className="how-to-play-title">How To Play?</h2>
            <Slider {...settings} className="how-to-play-slider">
                <div className="slide">
                    <img src={image1} alt="Choose Game Type" />
                    {/* <p>Choose Game Type</p> */}
                </div>
                <div className="slide">
                    <img src={image3} alt="Play The Game" />
                    {/* <p>Play The Game</p> */}
                </div>
                <div className="slide">
                    <img src={image2} alt="Buy Coins" />
                    {/* <p>Buy Coins</p> */}
                </div>
            </Slider>
        </div>
    );
};

export default HowToPlaySlider;