import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './view/Home';
import './assets/scss/main.scss';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivacyPolicy from './view/PrivacyPolicy';
import RefundPolicy from './view/RefundPolicy';
import TermsAndConditions from './view/TermsAndConditions';
import DeleteAccount from './view/DeleteAccount';
import About from './view/About';
import ScrollToTop from './components/ScrollToTop';


function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
