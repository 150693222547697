import React, { useState, useEffect } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, NavbarToggler, Collapse } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoImage from '../assets/images/logo.png';
import classnames from 'classnames';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTogglerActive, setIsTogglerActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    setIsTogglerActive(!isTogglerActive);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNavLinkClick = (event, targetId) => {
    event.preventDefault();
    if (window.innerWidth <= 768 && isOpen) {
      toggleNavbar();
    }

    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: targetId } });
    } else {
      scrollToSection(targetId);
    }
  };

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      scrollToSection(location.state.scrollTo);
      // Clear the state to prevent scrolling on subsequent renders
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, navigate]);

  return (
    <header className="header">
      <div className="container">
        <Navbar className="header-content" expand="md">
          <NavbarBrand className="logo" tag={Link} to="/">
            <img src={logoImage} alt="Logo" />
          </NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className={classnames({ 'active': isTogglerActive })}>
            <span></span>
            <span></span>
            <span></span>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto navigation" navbar>
              <NavItem>
                <NavLink onClick={(e) => handleNavLinkClick(e, 'home')} href="#home">Home</NavLink>
              </NavItem>

              <NavItem>
                <NavLink onClick={(e) => handleNavLinkClick(e, 'features')} href="#features">Platform Exclusivity</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={(e) => handleNavLinkClick(e, 'faqs')} href="#faqs">FAQ</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={(e) => handleNavLinkClick(e, 'How-to-Play')} href="#How-to-Play">How to Play</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className='ludo-download-button' href={process.env.REACT_APP_DOWNLOAD_LINK} target="_blank" rel="noreferrer">
                  Download
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;