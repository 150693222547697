// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            document.documentElement.scrollIntoView({
                behavior: 'instant', // This ensures no scrolling animation
                block: 'start',
            });
        };

        handleScroll();
    }, [pathname]);

    return null;
};

export default ScrollToTop;
