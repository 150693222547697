import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TermsAndConditions = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <>
            <Header />
            <div className="terms-conditions-container">
                <h1>Terms and Conditions</h1>
                <p className="last-updated">Last Updated: July 2024</p>

                <section>
                    <p>LUCKY PENNY FILMS (OPC) is an IT Company venturing in online gaming platforms that runs on the mobile application and the website, using unique and developed mobile application and website which enable to access the gaming platform, that the user can interact with and play "Ludo Carnival" from (the "Site" or "mobile application"). The mobile application and the website are collectively referred to in these Terms as the "App". Using the App, users can access the game.</p>
                    <p>LUCKY PENNY FILMS (OPC) ("LUCKY PENNY FILMS (OPC)", "we", or "us") is making the App available to you. Before you use the App, or the website, however, you will need to agree to these Terms of Use and any terms and conditions incorporated herein by reference (collectively, these "Terms"). Please read these terms carefully before using the app. These terms govern your use of the app, unless we have executed a separate written agreement with you for that purpose. We are only willing to make the app available to you if you accept all of these terms.</p>
                </section>

                <section>
                    <h2>The App</h2>
                    <p>For ease of accessibility use the App, we strongly recommend that you first install the App from Play Store. Once you have installed Play Store, you will need to install the game named "Ludo Carnival". The App will only recognize you as a user and you will only be able to interact with the App.</p>
                    <p>We neither own nor control Play Store, Google Chrome, or any other third-party site, product, or service that you might access, visit, or use for the purpose of enabling you to use the various features of the App. We will not be liable for the acts or omissions of any such third parties, nor will we be liable for any damage that you may suffer as a result of your transactions or any other interaction with any such third parties.</p>
                </section>

                <section>
                    <h2>Fees and Payment</h2>
                    <p>If you elect to play the App, or with or from other users via the App, any financial transactions that you engage in will be conducted solely through the mobile application via Play store. We will have no insight into or control over these payments or transactions, nor do we have the ability to reverse any transactions. With that in mind, we will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you engage in via the App, or using the website.</p>
                </section>

                <section>
                    <h2>Ownership; Restrictions; Grant of License</h2>
                    <p>You acknowledge and agree that we (or, as applicable, our licensors) own all legal rights, title and interest in and to all elements of the App, and all intellectual property rights therein. The visual interfaces, graphics (including, without limitation, all art and drawings associated with the App and LUCKY PENNY FILMS (OPC) (the "Art")), design, systems, methods, information, computer code, software, services, look and feel, organization, compilation of the content, code, data, and all other elements of the App (collectively, the "LUCKY PENNY FILMS (OPC) Materials") are owned by LUCKY PENNY FILMS (OPC), and are protected by copyright, trade dress, patent, and trademark laws, international conventions, other relevant intellectual property and proprietary rights, and applicable laws.</p>
                </section>

                {/* Add more sections for each part of the Terms and Conditions */}

                <section>
                    <h2>Dispute Resolution; Arbitration and Governing Law</h2>
                    <p>The Terms shall be governed by, construed and interpreted in accordance with laws of Australia, in force from time to time.</p>
                    <p>In case of any dispute arising between LUCKY PENNY FILMS (OPC) and you, the party raising the dispute shall issue a written notice to the other party. In case the notice is to be issued to you, sending the notice to the email address registered with LUCKY PENNY FILMS (OPC) shall constitute delivery. A notice to be issued to LUCKY PENNY FILMS (OPC) is to be served on (E-mail). On receipt of the said notice, the parties shall first try to resolve the dispute amicably through discussions.</p>
                </section>

                <section>
                    <h2>General</h2>
                    <p>These Terms constitute the entire legal agreement between you and LUCKY PENNY FILMS (OPC), govern your access to and use of the App, and completely replace any prior or contemporaneous agreements between the parties related to your access to or use of the App, whether oral or written. There are no third party beneficiaries to these Terms. The parties are independent contractors, and nothing in these Terms create any agency, partnership, or joint venture.</p>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default TermsAndConditions;