import React, { useEffect } from 'react'
import Header from '../components/Header'
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa'
import Footer from '../components/Footer'

const DeleteAccount = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
      
    return (
        <>
            <Header />
            <div className="about-page-container">
                <section className="about-content">
                    <h2>Delete Account</h2>
                    <p>If you wish to delete your account, please contact us using the methods below, and we will guide you through the process</p>
                    <div className="tagline">
                        <p>Play. Compete. Win. Experience the fantasy with Ludo Carnival!</p>
                    </div>
                </section>

                <section className="contact-section">
                    <h2>Contact Us</h2>
                    <div className="contact-cards">
                        <div className="contact-card">
                            <FaEnvelope className="contact-icon" />
                            <p>info@ludocarnival.com</p>
                        </div>
                        <div className="contact-card">
                            <FaPhone className="contact-icon" />
                            <p>9820520587</p>
                        </div>
                    </div>
                    <div className="contact-card address">
                        <FaMapMarkerAlt className="contact-icon" />
                        <h3>Head Office:</h3>
                        <p>C-405, Sarvoday Residency, Ward 15, Sheet 394, Indira Road, Near Air force 2, Sundram Park, Jamnagar, Gujrat-361001</p>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default DeleteAccount