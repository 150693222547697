import React, { useEffect, useState } from 'react';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaBars } from 'react-icons/fa';
import Header from '../components/Header';
import Footer from '../components/Footer';

const About = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])

    return (
        <>
            <Header />
            <div className="about-page-container">
                <section className="welcome-section">
                    <h1>Welcome to Ludo Carnival</h1>
                    <p>Where classic fun meets fantasy adventure!</p>
                </section>

                <section className="about-content">
                    <p>We're passionate about transforming the timeless game of Ludo into an enchanting experience that captivates players of all ages.</p>

                    <h2>Our Mission</h2>
                    <p>Our mission is to provide a seamless, immersive gaming experience with extensive scalability, robust security, and 24/7 support to ensure you can play with confidence and enjoyment. Our stunning graphics and engaging gameplay make every match a visual and strategic delight.</p>

                    <p>Join us at Ludo Carnival, where each roll of the dice opens a door to a new adventure. Whether you're competing globally or playing with friends, we promise a magical journey that makes every game unforgettable.</p>

                    <div className="tagline">
                        <p>Play. Compete. Win. Experience the fantasy with Ludo Carnival!</p>
                    </div>
                </section>

                <section className="contact-section">
                    <h2>Contact Us</h2>
                    <div className="contact-cards">
                        <div className="contact-card">
                            <FaEnvelope className="contact-icon" />
                            <p>info@ludocarnival.com</p>
                        </div>
                        <div className="contact-card">
                            <FaPhone className="contact-icon" />
                            <p>9820520587</p>
                        </div>
                    </div>
                    <div className="contact-card address">
                        <FaMapMarkerAlt className="contact-icon" />
                        <h3>Head Office:</h3>
                        <p>C-405, Sarvoday Residency, Ward 15, Sheet 394, Indira Road, Near Air force 2, Sundram Park, Jamnagar, Gujrat-361001</p>
                    </div>
                </section>

                 
            </div>
            <Footer />
        </>
    );
};

export default About;