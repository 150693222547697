import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo.png'; // Update this path
import { QRCodeCanvas } from 'qrcode.react';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleScrollTo = (id) => {
    if (location.pathname !== '/') {
      navigate('/');
      // We need to wait for the navigation to complete before scrolling
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleDownload = () => {
    window.open(process.env.REACT_APP_DOWNLOAD_LINK, '_blank', 'noopener,noreferrer');
  };

  return (
    <footer className="ludo-footer" id='footer'>
      <div className="ludo-footer-content">
        <div className="ludo-footer-logo-section">
          <div className='ludo-footer-logo'>
            <img src={logo} alt="Ludo Carnival Logo" className="ludo-footer-logo" />
          </div>
          <p className="ludo-footer-description">
          Ludo Carnival brings the classic game of Ludo to life with an exciting twist! Compete with players worldwide in real-money games, test your skills, and win big. Join now and experience the thrill of Ludo like never before!          </p>
        </div>
      </div>

      <div className="ludo-footer-links">
        <div className="ludo-footer-quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li><a onClick={() => handleScrollTo('home')}>Home</a></li>
            <li><a onClick={() => handleScrollTo('why-carnival')}>Why Ludo Carnival?</a></li>
            <li><a onClick={() => handleScrollTo('features')}>Platform Exclusivity</a></li>
            <li><a onClick={() => handleScrollTo('faqs')}>FAQs</a></li>
            <li><a onClick={() => handleNavigation('/about-us')}>About Us</a></li>
            <li><a onClick={() => handleNavigation('/delete-account')}>Delete Account</a></li>
          </ul>
        </div>

        <div className="ludo-footer-social">
          <h3>Connect With Us</h3>
          <div className="ludo-social-icons">
            <a href="https://www.facebook.com/people/Ludo-Carnival/61563956456664" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><FaFacebookF /></a>
            <a href="https://x.com/LudoCarnival"  target="_blank" rel="noopener noreferrer" aria-label="Twitter"><FaTwitter /></a>
            <a href="https://www.instagram.com/ludocarnival" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><FaInstagram /></a>
            <a href="https://www.youtube.com/@ludocarnival" target="_blank" rel="noopener noreferrer" aria-label="YouTube"><FaYoutube /></a>
          </div>
        </div>

        <div className="ludo-footer-download">
          <h3>Download the App via QR Code</h3>
          <div className="qr-code-container">
            <QRCodeCanvas value={process.env.REACT_APP_DOWNLOAD_LINK} />
            <div className="corner-top-right"></div>
            <div className="corner-bottom-left"></div>
          </div>
        </div>
      </div>

      <div className="ludo-footer-bottom">
        <p>© 2024 Carnival Ludo | Powered By</p>
        <p>Lucky Penny Films OPC</p>
        <button className="ludo-download-button" onClick={handleDownload}>Download App</button>
        <div className="ludo-footer-bottom-links">
          <a onClick={() => handleNavigation('/terms-and-conditions')}>Terms & Conditions</a>
          <a onClick={() => handleNavigation('/privacy-policy')}>Privacy Policy</a>
          <a onClick={() => handleNavigation('/refund-policy')}>Refund Policy</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;