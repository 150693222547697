import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const RefundPolicy = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
      },[])
    return (
        <>
            <Header />
            <div className="refund-policy-container">
                <h1>Cancellation & Refund Policy</h1>

                <section>
                    <p>All deposits and purchases made and fees paid on Ludocarnival to play or in connection with Fantasy Cricket are final. All transactions are final. We recognize that customer satisfaction is extremely important to us, so only error-oriented transaction cases shall be reviewed.</p>
                </section>

                <section>
                    <h2>Refund Process</h2>
                    <p>If you are not fully satisfied with any deposit/purchase made or fee paid on Ludocarnival and feel there is an 'Error' in the transaction, please let us know about that within 3 working days from the transaction date, and we shall certainly review the transaction and determine the resolution at our own discretion.</p>
                    <p>If we are satisfied with your request, we will initiate a refund in 7 working days, and you will get credit in 10 working days after initiating a refund in your registered bank account.</p>
                </section>

                <section>
                    <h2>Match Cancellation</h2>
                    <p>Ludocarnival reserves the right to cancel any match(es) for any reason, with or without disclosure of the reason, and refund all fees paid for such matches by any and all users within 1-3 working days.</p>
                </section>

                <section>
                    <h2>Game Room Policy</h2>
                    <p>Ludocarnival forbids users from joining any game rooms after a match related to the game room (identified by the Series) has begun.</p>
                </section>

                <section>
                    <h2>Transaction Safety</h2>
                    <p>Your transaction and refund are completely safe as Ludocarnival is solely owned by Ludocarnival Gaming Private Limited and no third-party involvement will be there to hamper your gaming experience.</p>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default RefundPolicy;