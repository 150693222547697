import React, { useState } from 'react'
import ludoBoardImage from '../assets/images/ludoBoardImage.png'
import instantwithdraw from '../assets/images/instant-withdraw-icon.png'
import activeusers from '../assets/images/active-users-icon.png'
import supportIcon from '../assets/images/support-icon.png'
import dailyMatches from '../assets/images/daily-matches-icon.png'
import AIGF from '../assets/images/AIGF.png'
import INSTANT from '../assets/images/INSTANT.png'
import ISO from '../assets/images/ISO.png'
import paymentmethods from '../assets/images/payment-methods.png'
import Multiplayer from '../assets/images/Icon/Multiplayer.png'
import SDK from '../assets/images/Icon/SDK.png'
import Support from '../assets/images/Icon/Support.png'
import Security from '../assets/images/Icon/Security.png'
import scalability from '../assets/images/Icon/scalability.png'
import Immersive from '../assets/images/Icon/Immersive.png'
import Footer from '../components/Footer'
import HowToPlaySlider from '../components/HowToPlaySlider '
import { QRCodeCanvas } from 'qrcode.react'
import Header from '../components/Header'



const FAQItem = ({ question, answer, isOpen, onToggle }) => {
  return (
    <div className={`ludo-faq-item ${isOpen ? 'active' : ''}`}>
      <div className="ludo-faq-question" onClick={onToggle}>
        <h3>{question}</h3>
        <span className="ludo-faq-toggle">{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && (
        <div className="ludo-faq-answer">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};


const Home = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const faqData = [
    {
      question: "How do I get started with ludo carnival?",
      answer: "To begin, download the Ludo Carnival app, create an account, and choose your game type and stakes. Once you're set up, you can start playing instantly or join a game with friends!"
    },
    {
      question: "What types of game are available in Ludo Carnival?",
      answer: "To begin, download the Ludo Carnival app, create an account, and choose your game type and stakes. Once you're set up, you can start playing instantly or join a game with friends!"
    },
    {
      question: "Is my personal information secure in Ludo Carnival?",
      answer: "To begin, download the Ludo Carnival app, create an account, and choose your game type and stakes. Once you're set up, you can start playing instantly or join a game with friends!"
    },
    {
      question: "What should I do if I encounter any issue while playing?",
      answer: "To begin, download the Ludo Carnival app, create an account, and choose your game type and stakes. Once you're set up, you can start playing instantly or join a game with friends!"
    }
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? -1 : index);
  };
  return (
    <div className="main-page">
      <Header />
      <main>
        <section className="hero-section" id='home'>
          <div className="container-hero-section">
            <div className="hero-content">
              <div className="hero-text">
                <div className='ludo-text'>
                  <h1>Play Ludo Game</h1>
                  <p>Win Real Money Up to Rs 1 Crore Daily!</p>
                </div>

                <div className='qr-code'>
                  <div className='qr-code-container'>
                    <QRCodeCanvas value={process.env.REACT_APP_DOWNLOAD_LINK} />
                    <div className="corner-top-right"></div>
                    <div className="corner-bottom-left"></div>
                  </div>
                  <div className='qr-code-text'>
                    <h3>Download The App</h3>
                    <h3>Via QR Code</h3>
                  </div>
                </div>

              </div>
              <div className="hero-image">
                <img src={ludoBoardImage} alt="Ludo Board" />
              </div>
            </div>
          </div>
          <div className="containers">
            <h2>Top Features</h2>
            <div className="features-container">
              <div className="feature-item">
                <a>Minimun 1</a>
                <img src={instantwithdraw} alt="Instant Withdraw" />
                <span>Withdraw</span>
              </div>
              <div className="feature-item">
                <a>1.5 Crore</a>
                <img src={activeusers} alt="Active Users" />
                <span> Active Users</span>
              </div>
              <div className="feature-item">
                <a>24/7 </a>
                <img src={supportIcon} alt="24/7 Support" />
                <span> Support</span>
              </div>
              <div className="feature-item">
                <a>10 Lakhs+ </a>
                <img src={dailyMatches} alt="Daily Matches" />
                <span> Daily Matches</span>
              </div>
            </div>
          </div>
        </section>
        <section className="certificates-section" id='why-carnival'>
          <div className="certificates-container">
            <h1>100% Safe & Secure Payments</h1>
            <div className="certificates">
              <div className="certificate"><img src={AIGF} alt="AIGF" /></div>
              <div className="certificate"><img src={INSTANT} alt="INSTANT" /></div>
              <div className="certificate"><img src={ISO} alt="ISO" /></div>
            </div>
            <div className="payment-methods">
              <img src={paymentmethods} alt="PhonePe" />
            </div>
          </div>
        </section>

        <section className="ludo-features-section" id='features'>
          <h1>Platform Features</h1>

          <div className="ludo-features-container">
            <div className="ludo-features-grid">
              <div className="ludo-feature-item">
                <div className='ludo-feature-top'>
                  <img src={scalability} alt="Scalability Icon" className="ludo-feature-icon" />
                  <h3 className="ludo-feature-title">Extensive Scalability</h3>
                </div>
                <p className="ludo-feature-description">Ludo Carnival's extensive scalability ensures a smooth experience whether you're playing with friends or competing in massive tournaments.</p>
              </div>
              <div className="ludo-feature-item">
                <div className='ludo-feature-top'>
                  <img src={Multiplayer} alt="Multiplayer Icon" className="ludo-feature-icon" />
                  <h3 className="ludo-feature-title">MultiPlayer</h3>
                </div>
                <p className="ludo-feature-description">Ludo Carnival's multiplayer games let you challenge friends and players worldwide in real-time, turning every match into a thrilling showdown!</p>
              </div>
              <div className="ludo-feature-item">
                <div className='ludo-feature-top'>
                  <img src={Security} alt="Security Icon" className="ludo-feature-icon" />
                  <h3 className="ludo-feature-title">Security</h3>
                </div>
                <p className="ludo-feature-description">Ludo Carnival prioritizes your safety with top-notch security features, ensuring a secure and fair gaming environment for every player.</p>
              </div>
              <div className="ludo-feature-item">
                <div className='ludo-feature-top'>
                  <img src={Support} alt="24/7 Support Icon" className="ludo-feature-icon" />
                  <h3 className="ludo-feature-title">24/7 Support</h3>
                </div>
                <p className="ludo-feature-description">Ludo Carnival offers 24/7 support to assist you anytime, ensuring a smooth and hassle-free gaming experience around the clock!</p>
              </div>
              <div className="ludo-feature-item">
                <div className='ludo-feature-top'>
                  <img src={SDK} alt="Solo Games Icon" className="ludo-feature-icon" />
                  <h3 className="ludo-feature-title">Solo Games</h3>
                </div>
                <p className="ludo-feature-description">Prefer to play solo? Test your skills against smart AI opponents and improve your strategy!</p>
              </div>
              <div className="ludo-feature-item">
                <div className='ludo-feature-top'>
                  <img src={Immersive} alt="Immersive Graphics Icon" className="ludo-feature-icon" />
                  <h3 className="ludo-feature-title">Immersive Graphics</h3>
                </div>
                <p className="ludo-feature-description">Ludo Carnival captivates with immersive graphics that bring every game to life, making each roll of the dice a visually stunning adventure!</p>
              </div>
            </div>
          </div>
        </section>

        <section className="ludo-faq-section" id='faqs'>
          <h2 className="ludo-faq-title">Ludo Carnival FAQs</h2>
          <div className="ludo-faq-container">
            {faqData.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                isOpen={openIndex === index}
                onToggle={() => handleToggle(index)}
              />
            ))}
          </div>
        </section>
        <HowToPlaySlider />
        <Footer />

      </main>
    </div>
  );
};

export default Home;